import React, { useContext } from 'react';
import { RecommendationsList } from '@recommendations-fe/list';
import { shape } from 'prop-types';
import { loadable } from 'nordic/lazy';
import RecommendationsIconFactory from '../../recommendations/icon-factory';
import jsonToProps from '../../recommendations/json-to-props';
import StaticPropsContext from '../../context/static-props';

const namespace = 'ui-seller-data-recos';

const RecommendationsCardHorizontalTitleFirst = loadable(() => import('../../recommendations/recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardHorizontalTitleFirst,
});

RecommendationsCardHorizontalTitleFirst.preload();

const RecommendationsListDouble = loadable(() => import('../../recommendations/recomendations-list'), {
  resolveComponent: components => components.RecommendationsListDouble,
});

RecommendationsListDouble.preload();

const Recommendations = props => {
  const { recommendationsContext, globalContext } = props;
  const { deviceType } = useContext(StaticPropsContext);

  return (
    <div className={`${namespace}__container`}>
      <RecommendationsList
        ListComponent={RecommendationsListDouble}
        listType="double"
        Card={RecommendationsCardHorizontalTitleFirst}
        deviceType={deviceType}
        IconFactory={RecommendationsIconFactory}
        className="list-seller"
        {...jsonToProps(props, recommendationsContext, globalContext)}
      />
    </div>
  );
};

Recommendations.propTypes = {
  recommendationsContext: shape({}),
  globalContext: shape({}),
};

Recommendations.defaultProps = {
  recommendationsContext: null,
  globalContext: null,
};

export default Recommendations;
